<script>
import { phone } from '@shared/config/vuelidate';
import { toBase64 } from '@shared/services/Utils';
import StoreDisableStoreForm from '@app/components/StoreDisableStoreForm.vue';
import TiptapEditor from '@shared/components/TiptapEditor.vue';

export default {
  components: { StoreDisableStoreForm, TiptapEditor },
  head: {
    title: 'Mon compte',
  },
  data() {
    return {
      isLoading: false,
      accept_email_notifications: true,
      user: {
        display_name: '',
        firstname: '',
        lastname: '',
        birthday: '',
        picture: '',
        phone: '',
        bio: '',
      },
    };
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
  },
  validations: {
    user: {
      phone: {
        phone,
      },
    },
  },
  created() {
    this.accept_email_notifications = this.authUser.accept_email_notifications;
    this.user.display_name = this.authUser.display_name;
    this.user.firstname = this.authUser.firstname;
    this.user.lastname = this.authUser.lastname;
    this.user.picture = this.authUser.picture;
    this.user.birthday = (this.authUser.birthday && this.$moment(this.authUser.birthday).toDate()) || null;
    this.user.phone = this.authUser.phone;
    this.user.bio = this.authUser.bio;
  },
  methods: {
    handle() {
      const user = { ...this.user };

      if (user.birthday) {
        user.birthday = this.$moment(user.birthday).format('YYYY-MM-DD');
      }

      if (user.picture && user.picture.startsWith('http')) {
        delete user.picture;
      }

      this.isLoading = true;
      this.$store.dispatch('auth/update', user)
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
    handleUpload(file) {
      if (file.size > this.$constants.MAX_IMAGE_SIZE) {
        this.$buefy.dialog.alert(this.$t('messages.file_larger_than', {
          size: '5 Mo',
        }));
        return;
      }

      const loader = this.$buefy.loading.open({ container: this.$refs.form });
      toBase64(file)
        .then((base64) => (this.user.picture = base64))
        .finally(() => loader.close());
    },
  },
};
</script>

<template>
  <div>
    <div class="columns">
      <div class="column is-4 content pr-10">
        <h2 class="is-size-5-mobile">
          Vos informations
        </h2>
        <p>
          Merci de compléter au mieux votre profil.
          Cela nous sera utile, notamment si vous nous contactez.
        </p>
        <p>
          Certaines informations (bio, photo de profil, prénom et nom)
          s'affichent en pied de page de vos pages de vente.
        </p>
      </div>

      <div class="column">
        <form ref="form" class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-field label="Pseudonyme affiché dans la Communauté">
                <b-input
                  v-model="user.display_name"
                  maxlength="128"
                  :has-counter="false"
                  placeholder="Laissez vide pour que votre prénom soit utilisé"
                />
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Prénom">
                <b-input v-model="user.firstname" maxlength="128" :has-counter="false" required />
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Nom">
                <b-input v-model="user.lastname" maxlength="128" :has-counter="false" required />
              </b-field>
            </div>
            <div class="column is-12">
              <b-field label="Photo">
                <b-upload @input="handleUpload" accept="image/*" expanded>
                  <b-button tag="a" class="button" icon-left="file-image" outlined>
                    Sélectionnez une image
                  </b-button>
                </b-upload>
              </b-field>
              <figure v-if="user.picture" class="image bradius-4 mw-200">
                <img class="w-auto" :src="user.picture" />
              </figure>
              <p v-if="user.picture">
                <b-button
                  type="is-text"
                  class="has-text-danger tdecoration-underline"
                  @click="user.picture = ''">
                  supprimer
                </b-button>
              </p>
            </div>
            <div class="column is-6">
              <b-field
                label="Numéro de téléphone (reste confidentiel)"
                v-bind="$getErrorProps($v.user.phone, ['phone'])"
              >
                <b-input v-model="user.phone" type="tel" placeholder="06XXXXXXXX" />
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Date de naissance (reste confidentielle)">
                <b-datepicker
                  v-model="user.birthday"
                  icon="calendar-alt"
                  position="is-bottom-left"
                  trap-focus
                />
              </b-field>
            </div>
            <div class="column is-12">
              <b-field type="is-info" message="Contactez-nous si vous souhaitez modifier votre adresse email. Nous le ferons pour vous.">
                <b-input v-model="authUser.email" disabled />
              </b-field>
            </div>
            <div v-if="$store.getters['auth/isRoleOwner']" class="column is-12">
              <b-field type="is-info" message="Votre code parrain n'est pas modifiable.">
                <b-input v-model="authUser.god_father_code" disabled />
              </b-field>
            </div>
            <div class="column is-12">
              <b-field
                label="Votre bio (affichée en bas de votre page d'accueil)"
                message="Une petite description du formateur aide les apprenants à être en confiance."
              >
                <TiptapEditor
                  :value="user.bio"
                  @input="user.bio = $event"
                />
              </b-field>
            </div>
          </div>

          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </div>
    </div>

    <div class="columns">
      <div class="column is-offset-4">
        <div class="box content">
          <h2 class="is-size-5-mobile">
            Notifications par email
          </h2>
          <p>
            Cette option nous permet de vous avertir par email quand&nbsp;:
          </p>
          <ul>
            <li>Un nouvel apprenant vient de s'inscrire</li>
            <li>Vous venez d'effectuer une nouvelle vente</li>
            <li>Vous changez votre mot de passe</li>
          </ul>
          <p>
            Que vous activiez ou non les notifications par email,
            vous aurez toujours les notifications dans votre espace formateur en cas de besoin.
          </p>
          <p>
            <b-switch
              v-model="accept_email_notifications"
              @input="$store.dispatch('auth/update', { accept_email_notifications })"
            >
              Notifications activées
            </b-switch>
          </p>
        </div>
      </div>
    </div>

    <StoreDisableStoreForm v-if="$store.getters['auth/isRoleOwner']" />
  </div>
</template>
